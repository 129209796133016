<!--
 * @Author: lbh
 * @Date: 2023-02-14 10:21:35
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:43:22
 * @Description: file content
-->
<template>
  <div class="edit-frequentlyAskedQuestion-box">
    <div class="cell-b">
      <div class="cell-b">
        <h4>標題</h4>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          :placeholder="`請輸入-${lItem.name}`"
          type="textarea"
          rows="3"
        />
      </div>
      <div class="cell-b">
        <h4>副標題</h4>
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}subTitle`]"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}subTitle`)"
          type="textarea"
          rows="3"
          :placeholder="`請輸入-${lItem.name}`"
        />
      </div>
    </div>

    <div class="cell-b">
      <div
        v-for="(item,index) in configs.items"
        :key="index"
        class="cell-b"
      >
        <div class="line">
          <h4>問題</h4>
          <!-- 操作 -->
          <el-button-group class="btn-group">
            <el-button
              type="primary"
              icon="el-icon-upload2"
              :disabled="index == 0"
              size="mini"
              @click="itemCheck(index,index-1)"
            ></el-button>
            <el-button
              type="success"
              icon="el-icon-download"
              :disabled="index == configs.items.length-1"
              size="mini"
              @click="itemCheck(index,index+1)"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              size="mini"
              :disabled="configs.items.length == 1"
              @click="itemDel(index)"
            ></el-button>
            <el-button
              type="warning"
              icon="el-icon-plus"
              size="mini"
              @click="itemAdd(index)"
            ></el-button>
          </el-button-group>
        </div>
        <div class="cell-b">
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
            @input="setValue('items')"
            :placeholder="`請輸入-${lItem.name}`"
            type="textarea"
            rows="3"
          />
        </div>
        <div class="cell-b">
          <h4>回答</h4>
          <el-input
            v-for="lItem in language"
            :key="lItem.code"
            v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
            @input="setValue('items')"
            type="textarea"
            :placeholder="`請輸入-${lItem.name}`"
            rows="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
export default {
  mixins: [langEditMixin],
  props: {
    configs: {
      default () {
        return {
          title: "",
          subTitle: "",
          items: []
        }
      }
    },
    index: {
      default () {
        return '';
      },
    },
  },
  methods: {
    setValue (key) {
      this.$emit('setValue', { key: key, value: this.configs[key] })
    },
    // 切換順序
    itemCheck (index, newIndex) {
      let items = this.configs.items;
      let _ = items.splice(index, 1);
      items.splice(newIndex, 0, _[0])
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 刪除
    itemDel (index) {
      let items = this.configs.items;
      items.splice(index, 1);
      this.$emit('setValue', { key: 'items', value: items });
    },
    // 新增
    itemAdd (index) {
      let items = this.configs.items;
      let item = this.$util.object.clone(items[index])
      item.title = '';
      item.desc = '';
      items.splice(index + 1, 0, item);
      this.$emit('setValue', { key: 'items', value: items });
    }
  }
}
</script>

<style lang="less" scoped>
.edit-frequentlyAskedQuestion-box {
  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  h4 {
    margin-top: 10px;
  }
}
</style>